.player-root-container {
  position: relative;
  flex: 1;
  height: 100%;
  width: 100%;
}
.bmpui-ui-watermark {
  background-image: url('../../../assets/images/svg/logo.svg') !important ;
  margin: 10px 10px 0px 0px !important;
  font-size: 0.7em !important;
}
.bmpui-ui-settingstogglebutton {
  background-image: url('../../../assets/images/svg/settings.svg') !important ;
}
.bmpui-ui-settingstogglebutton.bmpui-on {
  background-image: url('../../../assets/images/svg/settingsOn.svg') !important ;
}
.bmpui-ui-titlebar {
  padding: 1em;
}
.bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-playbackposition,
.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-playbackposition {
  background-color: #5ecf63;
}
.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-playbackposition-marker {
  background-color: #5ecf63;
}
.bmpui-ui-settingstogglebutton:active {
  color: #5ecf63;
}
.bmpui-ui-buffering-overlay .bmpui-ui-buffering-overlay-indicator {
  background-image: url('../../../assets/images/svg/loadingIcon.svg') !important ;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rent-button {
  width: 292px;
  color: white !important;
  background-color: #3A7248 !important;
  height: 36px;
  text-align: center;
  margin-top: 20px !important
}

.back-button {
  margin-top: 20px;
  margin-left: 20px;
  position: absolute;
  top: 0;
  left: 0
}

.bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-playbackposition-marker,
.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-playbackposition-marker {
  background-color: rgba(94, 207, 99, 0.4);
  border: 0.1875em solid #5ecf63;
  height: 0.9375em;
  left: -0.46875em;
  width: 0.9375em;
  border-radius: 50%;
}
.bmpui-ui-selectbox {
  color: #5ecf63;
}
.bmpui-ui-fullscreentogglebutton {
  background-image: url('../../../assets/images/svg/fullscreen.svg');
}
.bmpui-ui-fullscreentogglebutton.bmpui-on {
  background-image: url('../../../assets/images/svg/fullscreenOn.svg');
}
.bmpui-ui-back {
  background-image: url('../../../assets/images/svg/backButton.svg');
  background-size: contain;
  height: 18px;
  color: 'white';
}
.bmpui-ui-label {
  font-weight: 500;
  margin-right: 20px;
}

.safari-back-button-container {
  position: absolute;
  top: 34px;
  left: 30px;
  z-index: 100000;
}
.safari-back-button {
  background-image: url('../../../assets/images/svg/backButton.svg');
  background-size: contain;
  height: 18px;
  width: 18px;
  box-shadow: none;
  cursor: pointer;
}

.bmpui-ui-titlebar > .bmpui-container-wrapper {
  display: flex;
  padding-top: 10px;
  justify-content: center;
  align-items: center;
}

.bmpui-ui-titlebar > .bmpui-container-wrapper .bmpui-label-metadata-title {
  font-weight: 500;
  margin-right: 20px;
}

.bmpui-ui-titlebar .bmpui-container-wrapper .bmpui-ui-titlebar .bmpui-container-wrapper {
  display: flex;
  justify-content: center;
}

.bmpui-ui-rewindbutton {
  background-image: url('../../../assets/images/svg/rewindButton.svg');
  background-size: contain;
  height: 20px;
  margin-top: 1px;
}

.bmpui-ui-forwardbutton {
  background-image: url('../../../assets/images/svg/forwardButton.svg');
  background-size: contain;
  height: 20px;
  margin-top: 1px;
}

.bmpui-ui-rewindbutton:hover,
.bmpui-ui-forwardbutton:hover {
  -webkit-filter: drop-shadow(0 0 1px #fff);
  filter: drop-shadow(0 0 1px #fff);
}

.bmpui-ui-airplaytogglebutton {
  display: none;
}

.bmpui-ui-skin-smallscreen
  .bmpui-ui-titlebar
  > .bmpui-container-wrapper
  .bmpui-label-metadata-title {
  width: 100%;
  padding-left: 0px;
}

.titleRent{
  font-size: 25px !important;
}

@media screen and (max-width: 480px) {
  .titleRent {
    font-size: 20px !important;
  }
}