* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
  /* font-family: "Roboto", sans-serif,
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Pacifico';
  src: local('Pacifico'), url(./assets//fonts/Pacifico-Regular.ttf);
}

html,
body {
  height: 100%;
}

#pay-with-crypto {
  width: 100%;
}
.bg-blue-dark {
  background-color: black !important;
}

/* CSS hack to disable autofill color inside input fields */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
  transition-delay: background-color 5000s, color 5000s;
}

/* slightly transparent fallback */
.movie-backdrop {
  background-color: 'rgba(22, 21, 42, 0.4)';
}
.vjs-loading-spinner {
  display: none !important;
}

.background > .vjs-tech {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

.fa-facebook {
  background: transparent;
  color: white;
}

.fa-twitter {
  background: transparent;
  color: white;
}

.fa-instagram {
  background: transparent;
  color: white;
}

.fa {
  font-size: 40px;
  width: 40px;
  text-align: center;
  text-decoration: none;
  /* margin-top: 15px; */
  padding: 5px;
  font-family: Roboto, sans-serif;
}

.fa-facebook:hover {
  background-color: #3b5998;
  padding: 5px;
  border-radius: 6px;
}

.fa.fa-twitter:hover {
  background-color: #55acee;
  padding: 5px;
  border-radius: 6px;
}

.fa-youtube-play {
  background: transparent;
  color: white;
}
.fa-youtube-play:hover {
  background-color: #ff0000;
  padding: 5px;
  border-radius: 6px;
}

.fa.fa-instagram:hover {
  background-image: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  padding: 5px;
  border-radius: 6px;
}

.loading-container {
  flex: 1;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  opacity: 1;
  transition: opacity 0.6s, visibility 0s;
  visibility: visible;
}
.loading-container .loading-container-indicator {
  background-image: url('./assets/images/svg/loadingIcon.svg') !important ;
}

.loading-container .loading-container-indicator:first-child {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.loading-container .loading-container-indicator:nth-child(2) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.loading-container .loading-container-indicator:nth-child(3) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.loading-container .loading-container-indicator {
  -webkit-animation: e 2s ease-in infinite;
  animation: e 2s ease-in infinite;
  background: url('./assets/images/svg/loadingIcon.svg') no-repeat 50%;
  display: inline-block;
  height: 2em;
  margin: 0.2em;
  width: 2em;
}

/* if backdrop support: very transparent and blurred */
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .movie-backdrop {
    background-color: 'rgba(22, 21, 42, 0.1)';
    backdrop-filter: blur(30px);
  }
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
